import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageFormComponent } from './message-form/message-form.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { Message } from './message';
import { IfContent } from './iframecontent';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
// import { AgentChatComponent} from "src/app/admin/pages/agent-chat/agent-chat.component"
// import { Agentchat } from "src/app/admin/pages/agent-chat/chat.model"
import { ChatbotService } from './chatbot-service';
import { VoicerecognisionService } from './voicerecognision.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent implements OnInit {
  public message: Message;
  public messages!: Message[];
  //public agentdetails!: Agentchat;
  public sessionId = Math.random();
  private agentchatSub!: Subscription;
  public iframeContent!: IfContent;
  messagedata: any;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  selectedlang: string = 'en';
  isRecording!: boolean;
  breakpoint!: number;
  @Input('deviceType') deviceType: any;
  voiceAssistState: boolean = true;
  languageSelected: string = '';
  panelOpenState = true;
  iframeagentid: any;
  ifenvid: any;
  ifbotcolor: any;
  ifcustcolor: any;
  ifcustname: any;
  ifbotname: any;
  ifbotrole: any;
  ifboticon: any;
  ifbotmsgicon: any;
  ifcsmsgicon: any;
  iflangoption: any;
  openChat:boolean = false;
  chatClose: boolean = true;
  ifbotfontcolor: any;
  iflanguagesrequired: any;
  ifcustomerfontcolor: any;
  ifselectedchipcolor: any;
  ifsendbtncolor:any;
  ifchipcolor: any;
  payloadForTimeout: any;
  isLoader = false;
  isLoaderSub!:Subscription;

  @HostListener('window:message', ['$event'])
  onIframeMessage(event: any) {
    console.log('Event', event);
    //  localStorage.setItem("agentId",event.data);
    // console.log("chatBotIframe",JSON.stringify());
    if (event.data.agentid != undefined) {
      localStorage.setItem('agentid', JSON.stringify(event.data.agentid));
    }
    if (event.data.envid != undefined) {
      localStorage.setItem('envid', JSON.stringify(event.data.envid));
    }
    if (event.data.customername != undefined) {
      localStorage.setItem('customername',JSON.stringify(event.data.customername));
    }
    if (event.data.customercolor != undefined) {
      localStorage.setItem('customercolor',JSON.stringify(event.data.customercolor));
    }
    if (event.data.botcolor != undefined) {
      localStorage.setItem('botcolor', JSON.stringify(event.data.botcolor));
    }
    if (event.data.botname != undefined) {
      localStorage.setItem('botname', JSON.stringify(event.data.botname));
    }

    if (event.data.botrole != undefined) {
      localStorage.setItem('botrole', JSON.stringify(event.data.botrole));
    }

    if (event.data.boticon != undefined) {
      localStorage.setItem('boticon', JSON.stringify(event.data.boticon));
    }

    if (event.data.botmsgicon != undefined) {
      localStorage.setItem('botmsgicon', JSON.stringify(event.data.botmsgicon));
    }

    if (event.data.csmsgicon != undefined) {
      localStorage.setItem('csmsgicon', JSON.stringify(event.data.csmsgicon));
    }

    if (event.data.langoption != undefined) {
      localStorage.setItem('langoption', JSON.stringify(event.data.langoption));
    }

    if (event.data.botfontcolor != undefined) {
      localStorage.setItem('botfontcolor', JSON.stringify(event.data.botfontcolor));
    }
    if (event.data.customerfontcolor != undefined) {
      localStorage.setItem('customerfontcolor', JSON.stringify(event.data.customerfontcolor));
    }
    if (event.data.selectedchipcolor != undefined) {
      localStorage.setItem('selectedchipcolor', JSON.stringify(event.data.selectedchipcolor));
    }
    if (event.data.languagesrequired != undefined) {
      localStorage.setItem('languagesrequired',  JSON.stringify(event.data.languagesrequired));
    }
    if (event.data.sendbtncolor != undefined) {
      localStorage.setItem('sendbtncolor',  JSON.stringify(event.data.sendbtncolor));
    }
    if (event.data.chipcolor != undefined) {
      localStorage.setItem('chipcolor',  JSON.stringify(event.data.chipcolor));
    }
    let agentid = localStorage.getItem('agentid');
    let envid = localStorage.getItem('envid');
    let custname = localStorage.getItem('customername');
    let custcolor = localStorage.getItem('customercolor');
    let botcolor = localStorage.getItem('botcolor');
    let botname = localStorage.getItem('botname');
    let botrole = localStorage.getItem('botrole');
    let boticon = localStorage.getItem('boticon');
    let langoption = localStorage.getItem('langoption');
    let botmsgicon = localStorage.getItem('botmsgicon');
    let csmsgicon = localStorage.getItem('csmsgicon');
    let botfontcolor = localStorage.getItem('botfontcolor');
    let customerfontcolor = localStorage.getItem('customerfontcolor')
    let selectedchipcolor = localStorage.getItem('selectedchipcolor');
    let languagesrequired:any = localStorage.getItem('languagesrequired');
    let sendbtncolor = localStorage.getItem('sendbtncolor');
    let chipcolor = localStorage.getItem('chipcolor');

    this.iframeagentid = JSON.parse(agentid || '{}');
    this.ifenvid = JSON.parse(envid || '{}');
    this.ifcustname = JSON.parse(custname || '{}');
    this.ifcustcolor = JSON.parse(custcolor || '{}');
    this.ifbotcolor = JSON.parse(botcolor || '{}');
    this.ifbotname = JSON.parse(botname || '{}');
    this.ifbotrole = JSON.parse(botrole || '{}');
    this.ifboticon = JSON.parse(boticon || '{}').replace(/"/g, `'`);
    this.ifbotmsgicon = JSON.parse(botmsgicon || '{}').replace(/"/g, `'`);
    this.ifcsmsgicon = JSON.parse(csmsgicon || '{}').replace(/"/g, `'`);
    this.iflangoption = JSON.parse(langoption || '{}');
    this.ifbotfontcolor = JSON.parse(botfontcolor || '{}');
    this.ifcustomerfontcolor = JSON.parse(customerfontcolor || '{}');
    this.ifselectedchipcolor = JSON.parse(selectedchipcolor || '{}')
    this.iflanguagesrequired = JSON.parse(languagesrequired || '{}');
    this.ifsendbtncolor = JSON.parse(sendbtncolor || '{}')
    this.ifchipcolor = JSON.parse(chipcolor || '{}')

    var obj = [];
      for (var i = 0; i < this.iflanguagesrequired.length; i++) {
        let arr1 = {"text":this.iflanguagesrequired[i]}
         obj.push(arr1);
      }
    console.log("obj = ",obj);

    this.iframeContent = new IfContent(
      this.iframeagentid,
      this.ifenvid,
      this.ifcustname,
      this.ifcustcolor,
      this.ifbotcolor,
      this.ifbotname,
      this.ifbotrole,
      this.ifboticon,
      this.ifbotmsgicon,
      this.ifcsmsgicon,
      this.iflangoption,
      this.ifbotfontcolor,
      this.ifcustomerfontcolor,
      this.ifselectedchipcolor,
      this.iflanguagesrequired,
      this.ifsendbtncolor,
      this.ifchipcolor
    );
    let stringarray: string = 'Hi I am Bot';
    // console.log('Iflangoption', this.iflangoption);
    if (this.iflangoption == 'true') {
      this.messages = [
        new Message(
          'bot',
          ['Hello, Please select a language.'],
          [
            [
              {
                options: [
                  ...obj, 
                 
                ],
                type: 'chips',
              },
            ],
          ],
          'assets/bot.jpg',
          true
        ),
      ];
    } else {
      this.messages = [
        new Message('bot', ['Hey there, welcome.'], [], 'assets/bot.jpg', true),
      ];
    }
  }

  constructor(
    private voiceRecognition: VoicerecognisionService,
    private chatbotService: ChatbotService
  ) {
    this.message = new Message('user', [], '', 'src/assets/user.jpg', false);

    //  if(this.selectedlang === 'hi'){
    //   this.messages = [
    //     new Message('bot',['हाय मैं बोटा हूँ'],"",'assets/bot.jpg')
    //   ];
    //  }

    this.setTimeout();
    this.userInactive.subscribe(() =>
      console.log('user has been inactive for 3 minutes')
    );
  }

  setTimeout() {
    const payloadForTimeout = {
      agent_id: this.iframeagentid,
      session_id: this.sessionId,
      text: 'Session Timeout',
      translate_code: this.selectedlang,
      session_end: false,
    };
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
      this.chatbotService.sentAgentMessage(payloadForTimeout).then((response) => {
        console.log("api for timeout", response);
      });
      this.dialogChatClose();
    }, 180000);
  }

  onVoiceassistClick() {
    window.speechSynthesis.cancel();
    this.voiceAssistState = !this.voiceAssistState;
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log("keyborad event = ",event);
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnInit() {
    this.isLoaderSub = this.chatbotService.getData().subscribe(data => {
    if(data == 'true'){
      this.isLoader = true;
    }else{
      this.chatbotService.setChatbotData('Please type in your input');
      this.isLoader = false;
    }
  });
 }
 
ngOnDestroy() {
    if(this.isLoaderSub){
      this.isLoaderSub.unsubscribe();
    }
  }
  //****To recieve message from iframe****

  setCurrentLang(currentlang: string) {
    this.selectedlang = currentlang;
    this.voiceRecognition.setlanguage(this.selectedlang);
    if (this.selectedlang == 'ml') this.languageSelected = 'മലയാളം';
    else if (this.selectedlang == 'hi') this.languageSelected = 'हिन्दी';
    else if (this.selectedlang == 'kn') this.languageSelected = 'ಕನ್ನಡ';
    else if (this.selectedlang == 'de') this.languageSelected = 'German';
    else this.languageSelected = 'English';
  }

  onCloseClick() {
    this.chatClose = false;    
  }

  recodringStarted(isrecording: boolean) {
    this.isRecording = isrecording;
  }

  dialogChatClose() {
    this.messagedata = {
      agent_id: this.iframeagentid.toString(),
      session_id: this.sessionId,
      text: this.message.content,
      // env_id: this.ifenvid.toString(),
      translate_code: this.selectedlang,
      session_end: false,
    };

    //  }
    this.chatbotService.sentAgentMessage(this.messagedata).then((responseData) => {});
  }

  openChatFn(){
    this.openChat = !this.openChat;
  }

}
